import React, { useState } from 'react'
import AuthLayout from '../../components/layout/AuthLayout'
import Friends from './components/Friends'
import SendInviteToFriend from './components/SendInviteToFriend';
import InviteBox from './components/InviteBox';
import { useFetchReferrals, useRevokeReferral } from '../../hooks/inviteFriends/useInviteFriends';
import { toast } from 'react-toastify';

function InviteFriends() {
    const [activeTab, setActiveTab] = useState('friends');
 const user= JSON.parse(sessionStorage.getItem("ccAuth"))
 const {data: referrals, refetch}= useFetchReferrals(user.id)

 const revokeInvite= useRevokeReferral()
 const handleRevokeInvite=(referralNumber)=>{
revokeInvite.mutate({refferal_phone:referralNumber, user_id:user?.id}, {
    onSuccess:(data)=>{
        refetch()
        toast.dismiss()
        toast.success(`Revoke successful`)
    },
    onError:(err)=>{
        toast.dismiss()
        toast.error(`Unable to revoke invite`)
    }
})
 }


//  navigator.clipboard won't work on http site
 const copyReferralLink=()=>{
   
    const tempInput = document.createElement('input');
    tempInput.value = window.location.origin;
    document.body.appendChild(tempInput);
    tempInput.select();
    try {
        document.execCommand('copy');
        toast.dismiss();
        toast.success('Referral link Copied to clipboard!');
    } catch (err) {
        console.error('Failed to copy: ', err);
        toast.dismiss();
        toast.error('Failed to copy to clipboard');
    }
    document.body.removeChild(tempInput);
 }

    return (
    <AuthLayout>

    <div className='px-4 '>
      <Friends/>
   
    {/* Tab */}
    <div className="flex justify-between mt-4">
        <button className={`active bg-[#61A641] text-white px-4 py-2 rounded-lg`} onClick={() => setActiveTab('friends')}>Friends</button>
        {/* <button className={`active bg-[#c4c4c4] text-[#f8f8f8] px-4 py-2 rounded-lg`} onClick={() => setActiveTab('friendRequests')}>Friend Requests</button> */}
        <button className={`active bg-[#1DD9D9] text-white px-4 py-2 rounded-lg`} onClick={copyReferralLink}>Referral Link</button>
    </div>

    {/* Input  */}
    <SendInviteToFriend refetch={refetch}/>

    {/* List of Fiends */}
{
    activeTab === 'friends' && <div className="">
        <h4 className='font-semibold mb-3'>Accepted Invites </h4>
      {  referrals?.data?.map((friend, index) => (
            <div className="">
                <InviteBox name={friend?.refferal_phone} buttonLabel="Revoke" buttonColor={"#F24444"} buttonFunction={()=>handleRevokeInvite(friend?.refferal_phone)}/>
            </div>
        ))}
    </div>
}
    
    {/* List of Fiends Requests */}

    {
    activeTab === 'friendRequests' && <div className="">
        <h4 className='font-semibold mb-3'>Requests </h4>
      {  ["Femi", "Tosin", ].map((friend, index) => (
            <div className="" key={index}>
                <InviteBox name={friend} buttonLabel="Accept" buttonColor={"#61A641"} />
            </div>
        ))}

        <div className="w-full flex justify-end">
            <button className='px-8 py-4 bg-[#61A641] text-white rounded-lg'>Accept All</button>
        </div>
    </div>
}
    {/* Referral Link */}



    </div>
    </AuthLayout>
  )
}

export default InviteFriends
