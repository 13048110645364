/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import Layout from "../../components/layout/Layout";
// import boyIcon from "../../../assets/pointing-bubbles.png";
import boyIcon from "../../../assets/iphone.png";
import Avatar from "../../../assets/glo_avatar.png";
import NoSub from "../../../assets/no-sub.png";
import { Formik, Form, ErrorMessage } from "formik";
import {
  Box,
  Button,
  Container,
  Input,
  Typography,
  Checkbox,
  TextField,
} from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import Modal from "@mui/material";
import { useLogin } from "../../hooks/api/post";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();
const [search]= useSearchParams()
const isActive= search.get("active")
console.log({search, isActive})
  const [checked, setChecked] = useState(false);
  const [activeSub, setActiveSub] = useState(isActive==="true"?false:true);

  const loginAPI = useLogin();
  const loginSchema = Yup.object().shape({
    phone_number: Yup.string()
      .required("Mobile number is required")
      // .matches(
      //   /^(234)\d{10}$/,
      //   "Mobile number must be a 13 digits number that starts with 234"
      // ),
  });

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Layout>
      <Container>
        <Typography
          marginTop={"30px"}
          fontSize={"24px"}
          fontWeight={500}
          textAlign={"center"}
        >
          {activeSub ? (
            <span>
              DIAL <b>*577*8012*1#</b> to subscribe
            </span>
          ) : (
            <span>Subscribe with Glo to Play!</span>
          )}
        </Typography>
        <Box
          marginY={"20px"}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img style={{ maxWidth: "50%" }} src={activeSub ? Avatar : NoSub} />
        </Box>
        <Typography
          marginTop={"28px"}
          fontSize={"24px"}
          fontWeight={500}
          textAlign={"center"}
        >
          {activeSub ? "Fun Learning Games" : "How to Subscribe:"}
        </Typography>
        {activeSub ? (
          <Formik
            initialValues={{
              phone_number: "",
            }}
            validationSchema={loginSchema}
            onSubmit={(values, { setSubmitting }) => {
              localStorage.setItem("user_passport", values.phone_number)
              loginAPI.mutate(
                { phone_number: values.phone_number },
                {
                  onSuccess: (response) => {
                    toast.dismiss();
                    toast.success("Login Successful");
                    const res = response?.data;
                    console.log(res);
                    const userInfo = JSON.stringify({
                      token: res?.token,
                      userPassport: values.phone_number,
                      id: res?.user?.id,
                      displayName: res?.user?.display_name,
                      name: res?.user?.name,
                      ageRange: res?.user?.age_range,
                      avatar: res?.user?.avatar,
                    });

                    if (res.user.display_name === "null") {
                      sessionStorage.setItem("closeCreate", false);
                    }
                    sessionStorage.setItem(
                      "quizID",
                      "24df12d9-1116-4b57-a458-c2e39d6fd36a"
                    );
                    sessionStorage.setItem("ccAuth", userInfo);
                    sessionStorage.setItem(
                      "displayName",
                      res?.user?.display_name
                    );
                    if (res?.user?.first_login === 0) {
                      sessionStorage.setItem("newbie", "no");
                    } else {
                      sessionStorage.setItem("newbie", "yes");
                      localStorage.setItem("hideHelp", "false");
                    }
                    res?.user?.first_login === 1
                      ? navigate("../homepage")
                      : navigate("../homepage");
                    // console.log("g");
                  },
                  onError: (err) => {
                    toast.dismiss();
                    toast.error(err?.response.data?.message);
                    console.log(err?.response);
                    if (
                      err?.response.data?.message ===
                      "You Do Not Have An Active Account"
                    ) {
                      setActiveSub(false);
                    }
                    // navigate("../register");

                    //new implementation
                    // navigate("../started");
                  },
                }
              );
            }}
          >
            {({ setFieldValue }) => (
              <Form>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    marginTop: "50px",
                    border: "1px solid #ccc",
                    padding: "5px",
                    borderRadius: "7px",
                    background: "#fff",
                  }}
                  mt={1}
                >
                  <Input
                    placeholder="Enter your glo phone number"
                    disableUnderline={true}
                    data-testid="userLogin"
                    sx={{
                      fontSize: "12px",
                      flex: "1",
                      paddingLeft: "10px",
                    }}
                    onChange={(e) =>
                      setFieldValue("phone_number", e.target.value)
                    }
                  />

                  <Box mt={0}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="error"
                      sx={{
                        height: "30px",
                        fontSize: "12px",
                        boxShadow: "none",
                        background: "#F24444",
                        borderRadius: "8px",
                        textTransform: "capitalize",
                        paddingX: "30px",
                      }}
                      type="submit"
                      disabled={loginAPI?.isLoading}
                      data-testid="loginBtn"
                    >
                      {loginAPI?.isLoading ? "Logging in ..." : " Play"}
                    </Button>
                  </Box>
                  {/* <Box
                  my={2}
                  mt={1}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Checkbox mr={2} />
                  <Typography
                    variant="body2"
                    fontWeight={600}
                    fontSize={16}
                    sx={{
                      fontFamily: "'Poppins', sans-serif",
                      color: "#707070",
                    }}
                  >
                    Remember me
                  </Typography>
                </Box> */}
                </Box>
                <Box sx={{ color: "error.main" }}>
                  <ErrorMessage name="phone_number" />
                </Box>
              </Form>
            )}
          </Formik>
        ) : (
          <Box>
            <Typography
              fontSize={"12px"}
              marginTop={"20px"}
              marginBottom={"30px"}
            >
              <span
                style={{
                  color: "#61A641",
                  marginRight: "3px",
                  fontWeight: "600",
                }}
              >
                STEP 1:
              </span>
              Dial <b>*577*8012*1#</b> on your mobile phone.
            </Typography>
            <Typography fontSize={"12px"} marginBottom={"30px"}>
              <span
                style={{
                  color: "#61A641",
                  marginRight: "3px",
                  fontWeight: "600",
                }}
              >
                STEP 2:
              </span>
              Receive a subscription confirmation message.
            </Typography>
            <Typography fontSize={"12px"} marginBottom={"30px"}>
              <span
                style={{
                  color: "#61A641",
                  marginRight: "3px",
                  fontWeight: "600",
                }}
              >
                STEP 3:
              </span>
              Visit <a href="http://games.9ijakids.com" className="text-blue-500">http://games.9ijakids.com</a> to play games.
            </Typography>
           <Link to={`/qwiz/waiting`}>
            <Button
              // onClick={() => setActiveSub(true)}
              variant="contained"
              fullWidth
              color="error"
              sx={{
                height: "30px",
                fontSize: "12px",
                boxShadow: "none",
                background: "#F24444",
                borderRadius: "8px",
                textTransform: "capitalize",
                paddingX: "30px",
              }}
            >
              Confirm Subscription
            </Button>
            </Link>
          </Box>
        )}
      </Container>
    </Layout>
  );
}

export default LandingPage;
