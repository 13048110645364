import React, { useState } from "react";
import { useFetchLeaderBoard } from "../../hooks/api/fetch";
//style
import "./QwizLeaderboard.css";
//component
import Leaderboards from "./LeaderboardDisplay";
import AuthLayout from "../../components/layout/AuthLayout";
import Socket from "../../components/Socket/Socket";
//ICON / IMAGE
import ErrorIcon from "@mui/icons-material/Error";
import { useFetchGames } from "../../hooks/homepage/useHomepage";
import useHomepageLogic from "../homepage/useHomepageLogic";

export default function QwizLeaderboard() {
  const [quizID , setQuizID] = useState(sessionStorage.getItem("quizID"));
  const [reloader, setReloader] = useState(0);
  const getUserID = sessionStorage.getItem("ccAuth");
  const userID = JSON.parse(getUserID);
  const token = userID.token;
  const { fetchAllLeaderboardData, isLoading } = useFetchLeaderBoard(quizID);
  const [showPrevious, setShowPrevious] = useState(false);
  const [active, setActive] = useState("thisweek");

  // Get top 3 winners in separate array
  let TopWinners = fetchAllLeaderboardData?.data?.slice(0, 3);
  const TopWinnersPrevious = fetchAllLeaderboardData?.data?.slice(0, 3);

  //sort the array in assending order.
  let sortedArray = TopWinnersPrevious?.slice().sort(function (a, b) {
    return b - a;
  });
  let sortedArrayCurrent = TopWinners?.slice().sort(function (a, b) {
    return b - a;
  });

  // Get Rest of the Players
  const players = fetchAllLeaderboardData?.data?.slice(3);
  const Previousplayers = fetchAllLeaderboardData?.data?.slice(3);

  //rearrange Winner array
  const middleIndex = Math.floor(TopWinners?.length / 2);
  const firstElement = TopWinners?.shift();
  // set the Top winner in the middle
  const rearrangedArray = [
    ...(TopWinners ?? []).slice(0, middleIndex),
    firstElement,
    ...(TopWinners ?? []).slice(middleIndex),
  ];
  //rearrange Winner array
  const PreviousMiddleIndex = Math.floor(TopWinnersPrevious?.length / 2);
  const PreviousfirstElement = TopWinnersPrevious?.shift();
  // set the Top winner in the middle
  const rearrangedPreviousArray = [
    ...(TopWinnersPrevious ?? [])?.slice(0, PreviousMiddleIndex),
    PreviousfirstElement,
    ...(TopWinnersPrevious ?? [])?.slice(PreviousMiddleIndex),
  ];

  // console.log(fetchAllLeaderboardData);
  // console.log(sortedArray);
  // console.log(sortedArrayCurrent);
  // console.log(players);

  // HANDLE ACTIVE SCREEN
  const ActiveScreen = (id) => {
    setActive(id);
  };
const {fetchGames} = useHomepageLogic()
  return (
    <AuthLayout>
      <main className="leaderboardsection">
        <h1 className="leaderboarHeading">Leaderboard</h1>
        <section className="leaderboard">
          <div className="px-4 mb-4">

          <select name="" id="" className="select select-bordered w-full max-w-sm bg-[#61A641] text-white " onChange={(e)=>setQuizID(e.target.value)} >
            <option value="">Select Game</option>
           {fetchGames?.data?.data?.allGames?.map((game)=>(
            <option value={game?.id}>{game?.title}</option>
           ))}
          </select>
          </div>
          {/* <div className="leaderboardToggle">
            <button
              className={`${
                active === "thisweek" ? "thisweek" : "thisweekActive"
              }`}
              onClick={() => {
                ActiveScreen("thisweek");
                setShowPrevious(false);
              }}
            >
              This Week
            </button>
            <button
              className={`${active === "lastweek" ? "activeWeek" : "lastweek"}`}
              onClick={() => {
                ActiveScreen("lastweek");
                setShowPrevious(true);
              }}
            >
              Last Week
            </button>
          </div> */}
          {showPrevious ? (
            <>
              {fetchAllLeaderboardData?.lastWeekLeaderboard?.length < 1 ? (
                <div className="leaderboardDataError">
                  <ErrorIcon />
                  No leaderboard data for last week.
                </div>
              ) : (
                <Leaderboards
                  TopWinners={TopWinnersPrevious}
                  players={Previousplayers}
                  rearrangedArray={rearrangedPreviousArray}
                  fetchAllLeaderboardData={fetchAllLeaderboardData}
                  isLoading={isLoading}
                  sortedArray={sortedArray}
                  active={active}
                />
              )}
            </>
          ) : (
            <>
              {fetchAllLeaderboardData?.data?.length < 1 ? (
                <div className="leaderboardDataError">
                  <ErrorIcon />
                  No leaderboard data.
                </div>
              ) : (
                <Leaderboards
                  TopWinners={TopWinners}
                  players={players}
                  rearrangedArray={rearrangedArray}
                  // formatDate={formatDate}
                  fetchAllLeaderboardData={fetchAllLeaderboardData}
                  isLoading={isLoading}
                  sortedArray={sortedArrayCurrent}
                />
              )}
            </>
          )}
        </section>
      </main>
      <Socket setReloader={setReloader} quizID={quizID} />
    </AuthLayout>
  );
}
