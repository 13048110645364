import React, { useState } from 'react'
import { useAddReferral } from '../../../hooks/inviteFriends/useInviteFriends'
import { toast } from 'react-toastify'
function SendInviteToFriend({refetch}) {
  const addReferral= useAddReferral()
  const [referralCode, setReferralCode] = useState("")

  const handleAddReferral=(e)=>{
    e.preventDefault()
e.stopPropagation()
const user= JSON.parse(sessionStorage.getItem("ccAuth"))
    addReferral.mutate({referralCode, user_id: user.id}, {
      onSuccess:(data)=>{
        toast.dismiss()
        toast.success(data.message)
        refetch()

      },
      onError:(error)=>{
        toast.dismiss()
        toast.error(error?.response?.data?.message)
      }
    })
  }
  return (
    <div className="mt-3 py-4">
      <h4 className='font-semibold'>Invite Friend</h4>
    <form onSubmit={handleAddReferral}>
    <div className=' flex ' >
      <div className=" flex items-center justify-between border input input-bordered w-full pr-0">
       
        <input minLength={1} required pattern='^[0-9+]+$'  type="search" placeholder="Type in your friend’s  number" className=' px-2 w-full max-w-60 md:max-w-sm' onChange={(e)=>setReferralCode(e.target.value)} />
      <div className="bg-[#F24444]  rounded-lg">
        <button type="submit" className="p-3 px-5 text-white w-full" 
        // onClick={handleAddReferral}
        >Invite Friend</button>
      </div>
      </div>
    </div>
    </form>
    </div>
  )
}

export default SendInviteToFriend

