import React from 'react'

function InviteBox({name, buttonLabel, buttonColor, buttonFunction}) {
  return (
    <div className='flex justify-between items-center  p-4 mb-4 border border-[#61A641] rounded-lg'>
      <p>{name}</p>
      <button className={`text-[${buttonColor}] font-semibold` } onClick={buttonFunction}>{buttonLabel}</button>
    </div>
  )
}

export default InviteBox
