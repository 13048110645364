import { useMutation, useQuery } from "react-query"
import { request2 } from "../api/fetch"

export const useAddReferral = () => {
    const addFriend=async ({referralCode, user_id})=>{
        const {data}= await request2.post("/auth/add-referral", {
            refferal_phone: referralCode,
            user_id: user_id
        })
        return data
    }
    const mutation= useMutation(addFriend)
    return mutation
}
export const useFetchReferrals=(user_id)=>{
    const fetchReferrals=async ()=>{
        const {data}= await request2.get(`/auth/referrals/${user_id}`)
        return data
    }
    const query= useQuery(["fetch-referrals", user_id], fetchReferrals)
    return query
}
export const useRevokeReferral=()=>{
    const revokeReferrals=async ({user_id, refferal_phone})=>{
        const {data}= await request2.patch(`/auth/revoke-invite`, {
            refferal_phone,
            user_id
        })
        return data
    }
    const query= useMutation(revokeReferrals)
    return query
}