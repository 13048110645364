import React from "react";
import { ContentPagePanel } from "../helpPage/HelpPage";
import AuthLayout from "../../components/layout/AuthLayout";

import { trivia } from "../../../Data";
import Layout from "../../components/layout/Layout";
function TAC({title, section="tac"}) {
  const triviaList = [...trivia[section]].map((data) => {
    data.content = data.subtitle;

    if (!data.title) {
      data.title = "";
    }
    return data;
  });
  return (
    <Layout showBack>
      <ContentPagePanel title={title||"Terms and Conditions" }options={triviaList} />
    </Layout>
  );
}

export default TAC;
