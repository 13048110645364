import React from 'react'
import AuthLayout from '../../components/layout/AuthLayout'
import coinsIcon from "../../assets/images/coins.png"
import PointCard from './components/PointCard'
import { useClaimReward, useFetchRewards } from '../../hooks/rewards/useRewards'
import { toast } from 'react-toastify'
import { useFetchProfile } from '../../hooks/api/fetch'

function EarnPoints() {
    const user= JSON.parse(sessionStorage.getItem("ccAuth"))
    const {data: rewards, refetch:refetchRewards}= useFetchRewards(user.id)
  const claimReward= useClaimReward()
  const profileApi = useFetchProfile(user?.id);
  const handleClaims=(id)=>{
    toast.dismiss()
    toast.loading("Claiming reward...")
    claimReward.mutate({user_id:user.id, user_reward_id:id}, {
        onSuccess:()=>{
            toast.dismiss()
            toast.success("Reward claimed successfully")
            refetchRewards()
            profileApi.refetch()
        },
        onError:(error)=>{
            toast.dismiss()
            toast.error(error.response.data.message)
        }
    })
  }
  
    return (
    <AuthLayout>

    <div className='p-4'>
      <div className="my-4">
        <h3 className='mb-2'>Wallet Balance</h3>
        <PointCard title="Your Coins" text={profileApi?.data?.data?.userInfo?.coin_balance} icon={coinsIcon} />
      </div>
      <div className="my-4">
        <h3 className='mb-2'>How to earn points</h3>
       {rewards?.data?.map(item=><div className="mb-4">
        <PointCard title={item?.description}
         text={item?.title}
         icon={item?.avatar} 
         bgColor={item?.background_color} 
         progress={item?.status==="completed"?100:50} 
         id={item?.id}
          handleClaims={()=>handleClaims(item?.user_reward?.id)} 
          claimmable={item?.status==="completed" && item?.user_reward?.is_claimed===false}
          />

       </div>)}
      </div>
    </div>
    </AuthLayout>
  )
}

export default EarnPoints
