import { useQuery } from "react-query"
import { request2 } from "../api/fetch"

export const useFetchGames = ({pageNumber, pageSize, searchTerm, classroom_id, subject_id}) => {
const fetchGames =async ()=>{
const {data}=  await  request2.get(`/games/dashboard?pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchTerm}&classroom_id=${classroom_id}&subject_id=${subject_id}`)
return await data
}
const query= useQuery(["fetch-games", pageNumber, pageSize, searchTerm, classroom_id, subject_id], fetchGames)
return query
}

export const useFetchSubjects = () => {
const fetchSchools =async ()=>{
const {data}=  await  request2.get(`/games/subjects`)
return await data
}
const query= useQuery(["fetch-schools"], fetchSchools)
return query
}
export const useFetchClassrooms= () => {
const fetchSchools =async ()=>{
const {data}=  await  request2.get(`/games/classrooms`)
return await data
}
const query= useQuery(["fetch-classrooms"], fetchSchools)
return query
}

