import { useState } from "react"
import { useFetchGames,useFetchSubjects,useFetchClassrooms } from "../../hooks/homepage/useHomepage"

const useHomepageLogic = () => {
    const [searchTerm, setSearchTerm] = useState("")
    const [classroom_id, setClassroom_id] = useState("")
    const [subject_id, setSubject_id] = useState("")
    const subjectsAPI=useFetchSubjects()
    const classroomsAPI= useFetchClassrooms()
const fetchGames= useFetchGames({pageNumber:1, pageSize:1000, searchTerm, classroom_id, subject_id})
return {fetchGames, setSearchTerm, subjectsAPI,classroomsAPI, classroom_id, setClassroom_id, subject_id, setSubject_id}
}
export default useHomepageLogic