import React from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import "@fontsource/poppins";
import Image from "material-ui-image";
import qwizLogo from "../../../assets/glo-logo.png";
import LoremLogo from "../../../assets/lorem_logo.png";
import Ellipsis from "../../../assets/ellipsis.png";
import styles from "./Layout.module.css";
import VerticalMenu from "../VerticalMenu/VerticalMenu";
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function Layout({ children, showBack=false }) {
  return (
    <div>
      <Header showBack={showBack}/>
      <Container disableGutters className={styles.container}>
        <Box className={styles.content}>{children}</Box>
      </Container>
      <Footer />
    </div>
  );
}

export default Layout;
export function Header({showBack=false}) {
  return (
    <Box pt={1}>
      {/* support header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        px={3}
        mb={1}
        py={1}
      >
        <Box width={40}>
          <Image src={LoremLogo} color="transparent" />
        </Box>
        <Link to="/qwiz">
        <Box width={40}>
          <Image width={30} src={qwizLogo} color="transparent" />
        </Box>
        </Link>
        <Box width={40}>
          {/* <VerticalMenu icon={<Image src={Ellipsis} color="transparent" />}>
            <Box
              sx={{
                borderRadius: "5px",
                padding: "20px",
                background: "#fff",
              }}
            >
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "red",
                  fontSize: "12px",
                }}
              >
                Subscriptions
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "red",
                  fontSize: "12px",
                }}
              >
                FAQs
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  color: "red",
                  fontSize: "12px",
                }}
              >
                Logout
              </Button>
            </Box>
          </VerticalMenu> */}
        </Box>
      </Box>
      <Box
        height={40}
        sx={{
          backgroundColor: "#61A641",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h6"
          color="#fff"
          align="center"
          fontWeight={600}
          fontSize={12}
          sx={{
            fontFamily: "'Poppins', sans-serif",
          }}
        >
          For support or enquiries, please contact:{" "}
          <a
            href="https://wa.me/message/GBEFBYHAWUIRH1"
            target="_blank"
            // className={styles.link}
          >
            09157504476
          </a>
        </Typography>
      </Box>
     {showBack? <Box p={2}>
        <Link to={`/qwiz`}>
      <ArrowBackIcon />
        </Link>
        </Box>:null}
    </Box>
  );
}
export function Footer() {
  const auth = JSON.parse(sessionStorage.getItem("ccAuth"))?.userPassport;
  return (
    <Box py={4}>
      <Typography
        variant="h6"
        align="center"
        fontSize={12}
        sx={{
          fontFamily: "'Poppins', sans-serif",
          color: "#333333",
        }}
      >
       © 2024 9ijakids Educational Games
      </Typography>
      <div className="mt-2 flex items-center justify-center">
        <p>All Rights reserved</p>
      <Link to={`/qwiz/${!!auth?"tacs":"tac"}`} className="ml-2 underline border-l border-black pl-2">Terms and Conditions</Link>
      <Link to={`/qwiz/${!!auth?"privacys":"privacy"}`} className="ml-2 underline border-l border-black pl-2">Privacy Policy</Link>
      </div>
    </Box>
  );
}
