import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
// import boyIcon from "../../../assets/pointing-bubbles.png";
import boyIcon from "../../assets/images/boy-sad.png";
import { Box, Button, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PrizePot from "../../../components/prizePot/PrizePot";
import { useLogin } from "../../hooks/api/post";
import { toast } from "react-toastify";
// import { useLogPlayerActivity } from "../../hooks/logs/useLogger";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
function WaitingFailed() {
 



  return (
    <Layout>    
      <Container>
        
        <div className=" ">
            <h2 className="font-semibold text-center text-lg text-[#707070]">Issue Proccessing your request</h2>
            {/* <p className="text-center">The process may take up to a minute</p> */}
           {/* {loginAttempt} */}
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img style={{ maxWidth: "100%" }} src={boyIcon} alt="Happy Boy" />
        </Box>


        <div className="mt-2">
            <Link to="/qwiz">
            <Button
                variant="contained"
                fullWidth
                color="error"
                sx={{
                  height: "40px",
                  background: "#F24444",
                  borderRadius: "8px",
                }}
                >
           Retry with a different number
              </Button>
            </Link>

            <h2 className="my-4 font-bold text-center">Or</h2>

<div className="flex justify-center ">
         <Link to="https://wa.me/message/GBEFBYHAWUIRH1" target="_blank">
            <div className=" flex gap-4  w-full justify-center btn border border-[#F29F06] rounded-lg ">
                <p>Contact Support</p>
                <WhatsAppIcon/>
</div></Link>
            </div>
        </div>
      </Container>
    </Layout>
  );
}

export default WaitingFailed;