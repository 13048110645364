import React, { useEffect, useState } from "react";
import { Dialog, Paper, Box, Container, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
function Modal({ children, open, handleClose, width }) {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          width: width || "100%",
          backgroundColor: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <Container sx={{ backgroundColor: "transparent" }} disableGutters>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "right" }}
          mb={6}
        >
          <IconButton
            onClick={handleClose}
            color="error"
            sx={{ backgroundColor: "white" }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Paper
          sx={{
            width: "100%",
            borderRadius: "16px",
            minHeight: "100px",
          }}
        >
          {children}
        </Paper>
      </Container>
    </Dialog>
  );
}

export default Modal;
