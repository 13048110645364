import { useMutation, useQuery } from "react-query"
import { request2 } from "../api/fetch"

export const useFetchRewards=(user_id)=>{
    const fetchRewards=async ()=>{
        const {data}= await request2.get(`/rewards/all/${user_id}`)
        return data
    }
    const query= useQuery(["fetch-rewards", user_id], fetchRewards)
    return query
}
export const useClaimReward=()=>{
    const claimReward=async ({user_id, user_reward_id})=>{
        const {data}= await request2.post(`/rewards/claim/`, {user_id,user_reward_id})
        return data
    }
    const query= useMutation(claimReward)
    return query
}