import { useQuery } from "react-query"
import {  request2 } from "../api/fetch"

export const useFetchPlayGameUrl = ({id, user_id}) => {
    const fetchGameUrl = async() => {
        const { data } = await request2.post(`/games/play`, {user_id, content_id:id})
        return data
    }
    const query = useQuery({
        queryFn: fetchGameUrl,
        queryKey:["fetch game", id, user_id]
    })
    return query
}