import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
// import boyIcon from "../../../assets/pointing-bubbles.png";
import boyIcon from "../../assets/images/boy-stop.png";
import { Box, Button, Container } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import PrizePot from "../../../components/prizePot/PrizePot";
import { useLogin } from "../../hooks/api/post";
import { toast } from "react-toastify";
// import { useLogPlayerActivity } from "../../hooks/logs/useLogger";
import OptOut from "./features/OptOut";

function WaitingPage() {
  const navigate = useNavigate();
  const loginAPI = useLogin();
//   const logActivity = useLogPlayerActivity();
  const [loginResponse, setLoginResponse]= useState("")
  const [loginAttempt, setLoginAttempt]= useState(0)
const [timer, setTimer]= useState(10)


const [count, setCount] = useState(10);

useEffect(() => {
  const intervalId = setInterval(() => {
    setCount(prevCount => prevCount - 1);
    if(count<1){
        setLoginAttempt(prev=>prev+1)
    }
  }, 1000);

  // Clear interval on component unmount
  return () => clearInterval(intervalId);
}, [loginAttempt, count]);


  useEffect(() => {

    const user_passport = localStorage.getItem("user_passport");
    const rememberMe = true ||localStorage.getItem("rememberMe");
   
    if (!!user_passport && rememberMe) {
      console.log("autologin");
      if(count<1){
        setLoginAttempt(prev=>prev+1)
        setCount(prev=>prev+15)
    }
      loginAPI.mutate(
        { phone_number: user_passport, 
             },
        {
          onSuccess: (res) => {
            toast.dismiss();
            toast.success("Login Successful");
            // console.log({ femmmmi: res, number });
            const userInfo = JSON.stringify({
              token: res?.token,
              userPassport: user_passport,
              id: res?.user?.id,
              displayName: res?.user?.display_name,
              name: res?.user?.name,
              ageRange: res?.user?.age_range,
              avatar: res?.user?.avatar,
            });

            if (res.user.display_name === "null") {
              sessionStorage.setItem("closeCreate", false);
            }
            sessionStorage.setItem(
              "quizID",
              "24df12d9-1116-4b57-a458-c2e39d6fd36a"
            );
            sessionStorage.setItem("ccAuth", userInfo);
            sessionStorage.setItem("displayName", res?.user?.display_name);
            if (res?.user?.first_login === 0) {
              sessionStorage.setItem("newbie", "no");
            } else {
              sessionStorage.setItem("newbie", "yes");
              localStorage.setItem("hideHelp", "false");
            }
            res?.user?.first_login === 1
              ? navigate("../started")
              : navigate("../homepage");
          },
          onError: (err) => {

       
            toast.dismiss();
            // toast.error(err.response.data?.message);
            if (
              err.response.data?.message === "Number is not in correct format"
            ) {
              // setLoginResponse("Incorrect Format");
            }
            if (
              err.response.data?.message === "You Do Not Have An Active Account"
            ) {
              // setLoginResponse("No Subscription");
              if(loginAttempt>3){
            navigate("/qwiz/waiting-failed")
              // "http://ng-app.com/VasDigimobility/9ijakidsTriviaQuiz-24-Yes-23410220000025840-web";
            }
        
                // setLoginAttempt(prev=>prev+1)
                // navigate("../register");
            }

            //new implementation
            // navigate("../started");
          },
        }
      );
    }
  }, [loginAttempt]);

  return (
    <Layout>    
      <Container>
        
        <div className="mt-4 ">
            <h2 className="font-semibold text-center text-lg text-[#707070]">We are currently processing your subscription.</h2>
            <p className="text-center">The process may take up to a minute</p>
           {/* {loginAttempt} */}
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img style={{ maxWidth: "100%" }} src={boyIcon} alt="Happy Boy" />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <p className="text-center mb-3 text-lg">
            Login would retry again in <span className="text-[#61A641] font-semibold"> {count}</span>s
         
          </p>
          {/* <Box mt={0} sx={{ width: "100%", maxWidth: "236px" }}>
          <Link
          to={ `/qwiz?active=true`}
          className="secureDlink"
        >
              <Button
                variant="contained"
                fullWidth
                color="error"
                sx={{
                  height: "40px",
                  background: "#F24444",
                  borderRadius: "8px",
                }}
                >
           Subscribe
              </Button>
         </Link>
          </Box> */}
          {/* <div className="flex justify-center  items-center ">

          <OptOut />
          </div> */}
       
        </Box>
      </Container>
    </Layout>
  );
}

export default WaitingPage;