import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import {
  Avatar,
  Box,
  Button,
  Container,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import Image from "material-ui-image";
import "./Profile.css";
import AuthLayout from "../../components/layout/AuthLayout";
import CardLayout from "../../components/cardLayout/CardLayout";
import giftIcon from "../../../assets/gift-box.png";
import fireIcon from "../../../assets/fire.png";
import friendImage from "../../../assets/friends.png";
import Modal from "../../components/modal/Modal";
import {
  useFetchProfile,
  useFetchStreak,
  useFetchAvatars,
} from "../../hooks/api/fetch";
import { useUpdateProfile } from "../../hooks/api/post";
import { useUploadToAWS } from "../../../hooks/profile/useProfile";
function Profile() {
  const [showAvatars, setShowAvatars] = useState(false);
  const handleCloseAvatarModal = () => {
    setShowAvatars((show) => false);
  };
  const [userProfile, setUserProfile] = useState(
    JSON.parse(sessionStorage.getItem("ccAuth"))
  );
  const profileApi = useFetchProfile(userProfile?.id);
  const avatarApi = useFetchAvatars();
  const getStreak = useFetchStreak(userProfile?.userPassport);
  const updateProfileApi = useUpdateProfile();
  const [newAvatar, setNewAvatar] = useState(userProfile?.avatar);

  useEffect(() => {
    setUserProfile((prev) => ({
      ...prev,
      email: profileApi?.data?.data?.userInfo?.email,
      gender: profileApi?.data?.data?.userInfo?.gender,
    }));
    }, [profileApi?.data]);

  // console.log(avatarApi?.data?.data?.avatars, "avatars");

  // console.log(userProfile);
  const handleUpdateAvatar = () => {
    updateProfileApi.mutate(
      {
        userId: userProfile.id,
        data: {
          name: userProfile.name,
          email: userProfile.email,
          display_name: userProfile.displayName,
          avatar: newAvatar,
          first_login: false,
        },
      },
      {
        onSuccess: () => {
          toast.dismiss();
          toast.success("Profile updated successfully");
          sessionStorage.setItem(
            "ccAuth",
            JSON.stringify({ ...userProfile, avatar: newAvatar })
          );
          setUserProfile(prev=>({ ...userProfile, avatar: newAvatar }))

          handleCloseAvatarModal();
        },
        onError: (err) => {
          toast.error("Avatar update failed");
        },
      }
    );
  };

  if (profileApi.isLoading) {
    return <>Please Wait</>;
  }
  return (
    <AuthLayout>
      <Box>
        <Typography
          variant="h5"
          align="center"
          fontWeight={600}
          mt={{ xs: 1, lg: 2 }}
        >
          Edit Profile
        </Typography>
        <Box>
          <CardLayout>
            {/* <Stack direction="row" justifyContent="space-evenly" mt={1}>
              <Box
                sx={{
                  minWidth: "112px",
                  width: "100%",
                  maxWidth: "120px",
                  height: "48px",
                }}
              >
                <Card
                  title="Points"
                  subtitle="3220"
                  img={giftIcon}
                  //   img="../../../assets/gift-box.png"
                  bgColor="#F29F06"
                />
              </Box>
              <Box
                sx={{
                  minWidth: "112px",
                  width: "100%",
                  maxWidth: "120px",
                  height: "48px",
                }}
              >
                <Card
                  title="Rewards"
                  subtitle="32"
                  img={giftIcon}
                  //   img="../../../assets/gift-box.png"
                  bgColor="#61A641"
                />
              </Box>
              <Box
                sx={{
                  minWidth: "112px",
                  width: "100%",
                  maxWidth: "120px",
                  height: "48px",
                }}
              >
                <Card
                  title="Streak"
                  subtitle="3 days"
                  img={fireIcon}
                  //   img="../../../assets/gift-box.png"
                  bgColor="#F27405"
                />
              </Box>
            </Stack> */}
            {/* <Box mt={{ md: 3, xs: 2 }} mb={{ xs: 2, md: 4 }}>
              <FriendCard />
            </Box> */}
            <Button
              fullWidth
              onClick={() => {
                return setShowAvatars(true);
              }}
              sx={{ cursor: "pointer" }}
            >
              <AvatarCard img={userProfile?.avatar} />
            </Button>
            <Box mt={{ md: 4, xs: 2 }}>
              <ProfileForm
                userProfile={userProfile}
                // userProfile={profileApi.data?.userInfo}
                updateProfileApi={updateProfileApi}
              />
            </Box>
          </CardLayout>
        </Box>
      </Box>
      <AvatarForm
        open={showAvatars}
        handleClose={handleCloseAvatarModal}
        options={avatarApi?.data?.data?.avatars?.map((data) => {
          data.value = data.avatar_link;
          data.src = data.avatar_link;
          return data;
        })}
        value={newAvatar}
        setValue={setNewAvatar}
        handleUpdate={handleUpdateAvatar}
      />
    </AuthLayout>
  );
}

export default Profile;
function Card({ title, subtitle, img, alt, bgColor }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",
        width: "100%",
        height: "100%",
        borderRadius: "8px",
      }}
      bgcolor={bgColor}
    >
      <Box mx={1} sx={{ height: 24, width: 24 }}>
        <Image
          src={img}
          alt={alt}
          sx={{ height: 24, width: 24 }}
          color="transparent"
        />
      </Box>
      <Box>
        <Typography
          variant="body1"
          //   mb={0.1}
          fontWeight={900}
          fontSize={16}
          color="white"
        >
          {title}
        </Typography>
        <Typography variant="body1" color="white" fontSize={12}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
function FriendCard() {
  return (
    <Box
      sx={{ backgroundColor: "#8BD9D9", borderRadius: "16px" }}
      py={1}
      px={2}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Box justifySelf="flex-start" sx={{ maxWidth: "300px" }}>
          <Typography variant="body1" fontSize={20} fontWeight={900}>
            Friends
          </Typography>
          <Typography variant="body1" fontSize={10} fontWeight={900}>
            Play games and challenges with friends to win amazing prizes
          </Typography>
        </Box>
        <Box sx={{ minWidth: "76px" }}>
          <Image
            src={friendImage}
            alt="group of friends"
            color="transparent"
            cover={true}
            aspectRatio={16 / 9}
          />
        </Box>
        {/* <Box></Box> */}
      </Stack>
    </Box>
  );
}
function AvatarCard({ img }) {
  return (
    <Container disableGutters>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={{ xs: 1, md: 3 }}
        px={{ md: 4, xs: 2 }}
        sx={{ border: "1px solid #61A641", borderRadius: "16px" }}
      >
        <Typography variant="body1" fontSize={16} fontWeight={900}>
          Pick an Avatar
        </Typography>
        <Box>
          <Avatar
            src={img}
            alt="Avatar"
            sx={{ border: "1px dashed #61A641", height: 72, width: 72 }}
          />
        </Box>
      </Stack>
    </Container>
  );
}

function ProfileForm({ userProfile, updateProfileApi }) {
  const profileSchema = Yup.object().shape({
    display_name: Yup.string()
      .min(6, "Display Name should be at least 6 characters ")
      .max(30, "Display Name is Too Long!")
      .required("Display Name is Required"),
  });
  console.log({ userProfile });
  return (
    <Box>
      <Formik
        initialValues={{
          display_name: userProfile?.displayName,
          name: userProfile?.name,
          avatar: userProfile?.avatar,
          first_login: false,
          email: userProfile?.email,
          mobile: userProfile?.userPassport,
          gender:userProfile?.gender,
          age_range:userProfile?.ageRange
        }}
        enableReinitialize
        onSubmit={(values, { setSubmitting }) => {
          updateProfileApi.mutate(
            { userId: userProfile.id, data: values },
            {
              onSuccess: () => {
                toast.dismiss();
                toast.success("Display Name updated successfully");
                sessionStorage.setItem(
                  "ccAuth",
                  JSON.stringify({
                    ...userProfile,
                    displayName: values.display_name,
                    name: values.name,
                  })
                );
              },

              onSettled: () => {
                setSubmitting(false);
              },
            }
          );
        }}
        validationSchema={profileSchema}
      >
        {({ values, setFieldValue, handleBlur, isValid, isSubmitting }) => (
          <Form>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Box sx={{ width: "50%" }}>
                {/* <label>Mobile Number</label> */}
                <TextField
                  fullWidth
                  disabled
                  // label="Mobile Number"
                  defaultValue={values.mobile}
                  onChange={(e) => setFieldValue("mobile", e.target.value)}
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                {/* <label sx={{ display: "block" }}>Display Name</label> */}

                <TextField
                  fullWidth
                  required
                  //   label="Display Name"
                  value={values.display_name}
                  defaultValue={values.display_name}
                  onChange={(e) =>
                    setFieldValue("display_name", e.target.value)
                  }
                  name="display_name"
                  onBlur={handleBlur}
                />
                <Box sx={{ color: "error.main" }} mb={1} fontSize={12}>
                  <ErrorMessage name="display_name" />
                </Box>
              </Box>
            </Stack>
            <Stack
              mt={1}
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Box sx={{ width: "50%" }}>
                {/* <label>Full Name</label> */}
                <TextField
                  fullWidth
                  // disabled
                  //   label="Full Name"
                  placeholder="Full Name"
                  value={values.name}
                  defaultValue={values.name}
                  onChange={(e) => setFieldValue("name", e.target.value)}
                />
              </Box>
              <Box sx={{ width: "50%" }}>
                {/* <label>Email</label> */}
               
                <TextField
                  fullWidth
                  type="email"
                  placeholder="email"
                  value={values.email}
                  defaultValue={values.email}
                  onChange={(e) => setFieldValue("email", e.target.value)}
                />
              </Box>
            </Stack>
            <Stack
              mt={1}
              direction="row"
              justifyContent="space-between"
              spacing={2}
              pt={1}
            >
              <Box sx={{ width: "50%" }}>
                {/* <label>Full Name</label> */}
            
                <select
                  className="w-full border border-slate-400 rounded-md bg-transparent px-2 py-4"
                  value={values?.age_range || ""}
                  onChange={(e) => setFieldValue("age_range", e.target.value)}
                >
                  <option value="" disabled>
                    Select an age range
                  </option>
                  <option value={"5-7"}>5-7</option>
                  <option value={"8-12"}>8-12</option>
                  <option value={"13-15"}>13-15</option>
                </select>
              </Box>
              <Box sx={{ width: "50%" }}>
                {/* <label>Email</label> */}
                <select
                  className="w-full border border-slate-400 rounded-md bg-transparent px-2 py-4"
                  value={values?.gender || ""} // Ensure the value is initially an empty string
                  onChange={(e) => setFieldValue("gender", e.target.value)}
                >
                  <option value="" disabled>
                    Select a gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </Box>
            </Stack>
            <Box mt={{ xs: 2, md: 5 }}>
              <Button
                sx={{ textTransform: "capitalize" }}
                fullWidth
                disabled={!isValid || isSubmitting}
                type="submit"
                variant="contained"
                color="success"
                size="large"
              >
                {isSubmitting ? "Updating" : "Save"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
function AvatarForm({
  open,
  handleClose,
  options = [],
  setValue,
  value,
  handleUpdate,
  loading,
}) {
  const handleAvatarChange = (event, newAvatar) => {
    setValue(newAvatar);
  };
  const uploadToAWS = useUploadToAWS();
  const [file, setFile] = useState({
    name: "",
    url: "",
    size: "",
  });
  const handleUploadImage = (e) => {
    let file = e.target.files[0];
    
    console.log({
      name: file?.name,
    });
    setFile((prev) => ({ ...prev, name: file?.name }));
    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const toast_id = toast.loading("Uploading File Please wait...");
    uploadToAWS.mutate(formData, {
      onSuccess: (response) => {
        setValue(response?.url);
        handleAvatarChange ("event", response?.url) 
        toast.success("File Uploaded Successfully");
      },
      onError: (err) => {
        toast.error("Unable to upload file, Kindly try a smaller file size");
      },
      onSettled: () => {
        toast.dismiss(toast_id);
      },
    });
  };
  return (
    <Modal open={open} handleClose={handleClose}>
      <Container disableGutters>


<h3 className="font-semibold text-xl  m-4 pt-4">Upload Picture</h3>
      <Box sx={{ display: "flex",
        //  justifyContent: "center" 
         }}>
          <Button component="label">
            <Box
              sx={{
                border: "1px solid #61A641",
                // backgroundImage: ` url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23277BA0' stroke-width='4' stroke-dasharray='15' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e")`,
                // border-radius: 8px,
                borderRadius: "8px",
                height: "66px",
                minWidth: "128px",
                padding:"10px"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "center",
                  height: "100%",
                }}
              >
                {!file.name && (
                  <Box height={21} width={16} mr={1}>
                    {/* <Image aspectRatio={31 / 26} src={uploadIcon} /> */}
                  </Box>
                )}
                <Typography
                  variant="body1"
                  // width={52}
                  fontSize={12}
                  fontWeight={600}
                  color="#61A641"
                >
                  {file.name || "  Upload Picture"}
                </Typography>
              </Box>
            </Box>
            <input type="file" hidden onChange={handleUploadImage} />
          </Button>
        </Box>



        <Typography variant="h6" fontWeight={600} m={2} mb={0}>
          Choose Avatar
        </Typography>
        <ToggleButtonGroup
          exclusive
          value={value}
          onChange={handleAvatarChange}
          color="success"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-around",
            gap: "10px",
            padding: "10px",
          }}
        >
          {options.map((option, index) => (
            <ToggleButton
              key={index}
              value={option.value}
              aria-label="left aligned"
              sx={{
                border: "none",
              }}
            >
              <Avatar
                src={option.src}
                sizes="large"
                sx={{
                  width: 56,
                  height: 56,
                  border: "1px dashed #61A641",
                  padding: "10px",
                }}
              />
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <Box sx={{ display: "flex", justifyContent: "center" }} p={3}>
          <Button
            onClick={handleUpdate}
            type="submit"
            variant="contained"
            color="success"
            disabled={loading}
          >
            {loading ? "Updating..." : " Update Avatar"}
          </Button>
        </Box>
      </Container>
    </Modal>
  );
}
