import React from 'react'
import coinsIcon from "../../../assets/images/coins.png"
function Friends() {
  return (
    <div className='p-4 mt-4 flex justify-between bg-[#8BD9D9] rounded-2xl'>
      <div className="text-[#707070]">
        <h1 className='text-2xl font-bold'>Friends</h1>
        <p className=''>Play games and challenges with friends to win amazing prizes</p>
      </div>
      <div className="">
        <img src={coinsIcon} alt="Earn Prize" />
      </div>
    </div>
  )
}

export default Friends