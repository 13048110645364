import React, { useState } from 'react'
import filterIcon from "../../../../assets/filter-icon.png";
import { Menu, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import useHomepageLogic from '../useHomepageLogic';
function SearchBar({setSearchTerm, setClassroom_id, setSubject_id}) {
 const [tempSearchTerm, setTempSearchTerm] = useState("")

const {subjectsAPI, classroomsAPI}=useHomepageLogic()

//Filter menu 
 const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (values)=>{
    setClassroom_id(values.class)
    setSubject_id(values.subject)
    handleClose()
  }
  return (
    <div className='mt-3 py-4 flex ' >
      <div className=" flex items-center justify-between border input input-bordered w-full pr-0">
        <button className='border-r-2 px-1 pr-2'  id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
          <img src={filterIcon} alt="filter" />
        </button>

        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <div className=" p-4 text-[#707070] ">
<Formik
initialValues={{
  class:"",
  subject:""
}}
onSubmit={handleSubmit}
>
 { ({setFieldValue})=><Form>
<h3 className="mb-4 ">Filter by </h3>
<div className="flex gap-10">


  <div className="">
    <h2 className='mb-4 font-semibold'>Class</h2>
   {classroomsAPI.data?.data?.classrooms?.map(item=>
   <div className="flex items-center ">
    <input type="radio" name="class"   value={item?.id} onChange={(e)=>setFieldValue("class", e.target.value)}/>
    <p className='ml-2'>
{item?.classroom_title}
    </p>
    </div>
  )}
  </div>

  <div className="">
    <h2 className='mb-4 font-semibold'>Subject</h2>
    {subjectsAPI.data?.data?.subjects?.map(item=>
   <div className="flex items-center ">
    <input type="radio" name="subject"   value={item?.id} onChange={(e)=>setFieldValue("subject", e.target.value)}/>
    <p className='ml-2'>
{item?.subject_title}
    </p>
    </div>
  )}
  </div>
  <div className="mt-8"></div>
</div>
<div className="mt-8">

<button type='submit' className='btn bg-[#61A641]'>Apply</button>
</div>
  </Form>}
</Formik>
        </div>
      </Menu>


        <input type="search" placeholder="Search" className='w-full px-2' onChange={(e)=>setTempSearchTerm(e.target.value)} />
      <div className="bg-[#F07404] rounded-lg">
        <button className="p-3 px-5 text-white" onClick={()=>setSearchTerm(tempSearchTerm)}>Search</button>
      </div>
      </div>
      
    </div>
  )
}

export default SearchBar


function FilterDropdown({isShowing, classes}){
  return (
    
   <>
   
    </>)
}