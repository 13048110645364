import axios from "axios";
import { useMutation } from "react-query";

export const useUploadToAWS = () => {
    async function updateDN(payload) {
      const { data } = await axios.post(`https://pollapi.9ijakids.com/admin/image/aws-upload`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    }
    const updateDisplayName = useMutation(updateDN);
    return updateDisplayName;
  };