import React from 'react'
function PointCard({title, text, icon, bgColor, progress, claimmable, handleClaims}) {
  return (
    <div className={`p-4 flex justify-between items-center bg-[${bgColor||"#F07404"}] rounded-2xl`} style={{backgroundColor:bgColor}}>
      <div className="text-white">
        <h3 className='text-lg font-bold'>{title}</h3>
        <h1 className='text-2xl  font-bold'>{text}</h1>
       {!!progress ? <progress className="mt-3 progress w-56 h-4 progress-warning text-white" value={progress} max="100"></progress>:null
 }     </div>
     {!claimmable? <div className="ml-2">
        <img src={icon} alt="Earn Prize" />
      </div>:
      <div className="ml-2">
        <button className='text-[#61a641] bg-white px-4 py-2 rounded-lg' onClick={handleClaims}>Claim</button>
      </div>}
    </div>
  )
}

export default PointCard
